<div class="modal-header">
    <div class="title">
        <h2 class="modal-title">Modifier mes informations</h2>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body d-flex flex-column ">
    <div class="info-modify">
        <!-- <div class="container"> -->
            <!-- <div class="row"> -->
                <!-- Last name -->
                <div class="col">
                    <label for="nom" class="form-label required">Nom</label>
                    <input type="text" class="form-control" id="nom" [(ngModel)]="informations.lastName" required>
                </div>

                <!-- First name -->
                <div class="col">
                    <label for="prenom" class="form-label required">Prénom</label>
                    <input type="text" class="form-control" id="prenom" [(ngModel)]="informations.firstName" required>
                </div>

                <!-- <div class="w-100"></div> -->
                
                <!-- Email -->
                <div class="col">
                    <label for="email" class="form-label required">Email</label>
                    <input type="email" class="form-control" id="email" disabled [(ngModel)]="informations.email"
                    required>
                </div>
                
                <!-- Phone -->
                <div class="col">
                    <label for="tel" class="form-label required">N° téléphone</label>
                    <input type="text" class="form-control" id="tel" [(ngModel)]="informations.phone" required>
                </div>

                <!-- <div class="w-100"></div> -->

                <!-- subDomainName -->
                <div class="col" *ngIf='this.informations.role === "Shop"'>
                    <label for="subDomainName" class="form-label required">Site marchand</label>
                    <input type="text" class="form-control" id="subDomainName" [(ngModel)]="subDomainName" required>
                </div>
            <!-- </div> -->
        <!-- </div> -->
    </div>
    <div class="col-sm-12 d-flex flex-column modify-block">
        <button class="modify" (click)="saveModification()">
            Sauvegarder les modifications
        </button>
    </div>
</div>