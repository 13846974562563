import { UsersService } from './../services/users.service';
import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Informations } from '../interfaces/informations';

@Component({
selector: 'app-account-modification',
templateUrl: './account-modification.component.html',
styleUrls: ['./account-modification.component.scss'],
encapsulation: ViewEncapsulation.None
})
export class AccountModificationComponent implements OnInit {

	@BlockUI() blockUI: NgBlockUI;
	@Input() id: string;
	@Input() subDomainName: string;
	@Output() updateDataAccount: EventEmitter<{success: boolean, phone:string, subDomainName: string}> = new EventEmitter();

	informations: Informations = {
		lastName: "",
		firstName: "",
		email: "",
		phone: "",
	}

	constructor(
		public activeModal: NgbActiveModal,
		private toastrService: ToastrService,
		private usersService: UsersService,
	) { }

	ngOnInit(): void {
		this.getUserById();
		this.subDomainName = this.subDomainName;
	}

	// get user infos by id
	getUserById(): void{
		this.blockUI.start('Loading');

		this.usersService.getCoreUserById(this.id).subscribe(
			(res) => {
				if(res.success) {
					this.informations = res.data;
					this.blockUI.stop();
				} else {
					this.blockUI.stop();
					if (res.errorSet.includes('InvalidRequest')) {
						this.toastrService.error('Les paramètres sont invalides');
					} else if (res.errorSet.includes('NotFound')) {
						this.toastrService.error('Utilisateur non trouvé');
					}
				}
			},
			(error) => {
				this.blockUI.stop();
				// console.error(error);
				this.toastrService.error('Une erreur s\'est produite');
			}
		);
	}

	saveModification(): void{
		this.blockUI.start('Loading');
		
		if (!this.informations.firstName || !this.informations.lastName || !this.informations.phone){
			this.toastrService.error('Veuillez remplir les champs manquants');
			this.blockUI.stop();

		} else if (this.informations.phone.length < 10) {
			this.toastrService.error('Le format du numéro de téléphone est invalide');
			this.blockUI.stop();

		} else{
			this.activeModal.close();

			let data = {
				id: this.id,
				jwt: localStorage.getItem('token'),
				firstName: this.informations.firstName,
				lastName: this.informations.lastName,
				phone: this.informations.phone,
				newEmail: this.informations.email,
				previousEmail: this.informations.email,
				fromAccountModification: true,
				subDomainName: undefined,
			};			

			if (this.informations.role === "Admin") {

				this.usersService.updateCoreUser(data).subscribe(
					(res: any) => {
						if(res.success) {
							this.toastrService.success('Mise à jour');
							this.blockUI.stop();
						} else {
							this.blockUI.stop();
							if (res.errorSet.includes('INVALID_PARAMS') || res.errorSet.includes('INVALID_REQUEST')) {
								this.toastrService.error('Les paramètres sont invalides');
							} else if (res.errorSet.includes('NOTFOUND')){
								this.toastrService.error('Utilisateur non trouvé');
							} else if (res.errorSet.includes('ERROR_SERVER') || res.errorSet.includes('SERVER_ERROR')) {
								this.toastrService.error('Une erreur serveur est survenue');
							}
						}
					},
					(error) => {
						this.blockUI.stop();
						// console.error(error);
						this.toastrService.error('Une erreur s\'est produite');
					}
				);
			}

			if (this.informations.role === "Provider") {

				data.id = this.informations.id_org_internal;
				data.newEmail = this.informations.email;
				data.previousEmail = this.informations.email;

				this.usersService.modifyProvider(data).subscribe(
					(res: any) => {
						if(res.success) {
							this.toastrService.success('Mise à jour');
							this.blockUI.stop();
							this.updateDataAccount.emit({success: true, phone: res.data.phone, subDomainName: undefined});
						} else {
							this.blockUI.stop();
							if (res.errorSet.includes('INVALID_PARAMS') || res.errorSet.includes('INVALID_REQUEST')) {
								this.toastrService.error('Les paramètres sont invalides');
							} else if (res.errorSet.includes('NOTFOUND')){
								this.toastrService.error('Utilisateur non trouvé');
							} else if (res.errorSet.includes('ERROR_SERVER') || res.errorSet.includes('SERVER_ERROR')) {
								this.toastrService.error('Une erreur serveur est survenue');
							} else if (res.errorSet.includes('EMAIL_EXIST')) {
								this.toastrService.error('Cet email est déjà utilisé');
							}
						}
					},
					(error) => {
						this.blockUI.stop();
						// console.error(error);
						this.toastrService.error('Une erreur s\'est produite');
					}
				);
			}

			if (this.informations.role === "Shop") {

				data.id = this.informations.id_org_internal;
				data.newEmail = this.informations.email;
				data.previousEmail = this.informations.email;
				data.subDomainName = this.subDomainName;
				
				this.usersService.modifyShop(data).subscribe(
					(res: any) => {
						if(res.success) {
							
							this.toastrService.success('Mise à jour');
							this.blockUI.stop();
							this.updateDataAccount.emit({success: true, phone: res.data.telephoneNumber, subDomainName: res.data.subDomainName});
						} else {
							this.blockUI.stop();
							if (res.errorSet.includes('INVALID_PARAMS') || res.errorSet.includes('INVALID_REQUEST')) {
								this.toastrService.error('Les paramètres sont invalides');
							} else if (res.errorSet.includes('NOTFOUND')){
								this.toastrService.error('Utilisateur non trouvé');
							} else if (res.errorSet.includes('ERROR_SERVER') || res.errorSet.includes('SERVER_ERROR')) {
								this.toastrService.error('Une erreur serveur est survenue');
							} else if (res.errorSet.includes('EMAIL_EXIST')) {
								this.toastrService.error('Cet email est déjà utilisé');
							}
						}
					},
					(error) => {
						this.blockUI.stop();
						// console.error(error);
						this.toastrService.error('Une erreur s\'est produite');
					}
				);
			}
		}
	}
}
