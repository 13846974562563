import {EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
  @Output() getNavBarList: EventEmitter<any> = new EventEmitter();

  baseAPI: string = environment.baseURL;

  constructor(private httpClient: HttpClient) { }

  //in: {email, pass}, out: jwt
  verifyLogin(data: any): Observable<any>{
    return this.httpClient.post(`${this.baseAPI}/verifyCoreLogin`, data);
  }

  //in: {jwt}, out: info
  decodeJWT(jwt: string): Observable<any>{
    return this.httpClient.post(`${this.baseAPI}/decodeCoreJWT`, {jwt: jwt});
  }

  logConnection(idClient: string): Observable<any>{
    return  this.httpClient.post(`${this.baseAPI}/logConnection`, {idClient: idClient})
  }

  signIn(jwt: string): void {
    localStorage.setItem('token', jwt);
    this.getLoggedInName.emit(jwt);
  }

  signOut(): void{
    localStorage.removeItem('token');
    this.getNavBarList.emit(localStorage.getItem('token'));
  }
}
