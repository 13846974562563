// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseURL: 'https://preprod-api.maisondumenuisier.fr',
    fileServer: 'https://preprod-fs.maisondumenuisier.fr/',
    firebase: {
        apiKey: "Your Api Key",
        authDomain: "Your Auth Domain",
        databaseURL: "Your Database Url",
        projectId: "Your Project Id",
        storageBucket: "Your StorageBucket url",
        messagingSenderId: "Your Sender Id"
    },
    mangopayAPI: {
        MDM : {
            clientID: 'mdmtest',
            keyAPI: 'gKxYC7rB9oFPto58ca8stYyYRwyYFqhDb8aXpjrdCkmW6ZX7WC'
        },
        SM : {
            clientID: 'smtest',
            keyAPI: 'NBs0yYt2gpMYCX0rbeWB3r16SP6jWLaX9PrRD08nHNvpeZT1ky'
        },
        link : 'https://api.sandbox.mangopay.com/v2.01/'
    }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
