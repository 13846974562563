import { UsersService } from './../../../bo/services/users.service';

import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { TranslateService } from '@ngx-translate/core';
// import { AuthService } from '../../services/firebase/auth.service';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/bo/services/auth.service';
import {AccountModificationComponent} from "../../../bo/account-modification/account-modification.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {Router} from "@angular/router";

var body = document.getElementsByTagName("body")[0];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public menuItems: Menu[];
  public items: Menu[];
  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public openNav: boolean = false
  public right_sidebar: boolean = false
  public text: string;
  public elem;
  public isOpenMobile: boolean = false
  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  @BlockUI() blockUI: NgBlockUI;

  userData: user = {
    id: "",
    firstName: "",
    lastName: "",
    idRole: "",
    role: "",
    photoURL: "",
  };

  constructor(
    public navServices: NavService,
    @Inject(DOCUMENT) private document: any,
    private translate: TranslateService,
    public authService: AuthService,
    private headerModalService: NgbModal,
    private router: Router,
    public usersService: UsersService,
  ) {
    translate.setDefaultLang('en');
    authService.getLoggedInName.subscribe((token) => {
      this.getCoreUserById(token)
    });
    usersService.getLoggedInName.subscribe((token) => {
      this.getCoreUserById(token)
    });
    usersService.updateHeader.subscribe((result) => {
      result.subscribe((response) => {
        if (response.success) {
          this.userData.lastName = response.data.lastName;
          this.userData.firstName = response.data.firstName;
        }
      })
      
    });
  }

  ngOnDestroy() {
    this.removeFix();
  }

  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  public changeLanguage(lang) {
    this.translate.use(lang)
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) return this.menuItems = [];
    let items = [];
    term = term.toLowerCase();
    this.items.filter(menuItems => {
      if (menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link') {
        items.push(menuItems);
      }
      if (!menuItems.children) return false
      menuItems.children.filter(subItems => {
        if (subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
          subItems.icon = menuItems.icon
          items.push(subItems);
        }
        if (!subItems.children) return false
        subItems.children.filter(suSubItems => {
          if (suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon
            items.push(suSubItems);
          }
        })
      })
      this.checkSearchResultEmpty(items)
      this.menuItems = items
    });
  }

  checkSearchResultEmpty(items) {
    if (!items.length)
      this.searchResultEmpty = true;
    else
      this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
    body.classList.add("offcanvas");
  }

  removeFix() {
    this.searchResult = false;
    body.classList.remove("offcanvas");
    this.text = "";
  }
  ngOnInit() {
    const token = localStorage.getItem('token');
    if (token)
      this.getCoreUserById(token);
    this.elem = document.documentElement;
    this.navServices.items.subscribe(menuItems => {
      this.items = menuItems
    });
    // this.navServices.collapseSidebar = true;
  }

  getCoreUserById(token: string): void{
    this.blockUI.start('Loading');

    this.authService.decodeJWT(token).subscribe(
      (res) => {
        if (res.success){
          this.userData.role = res.data.role;
          this.userData.id = res.data.id_user;
          this.userData.idRole = res.data.id_role;
          this.usersService.getCoreUserById(this.userData.id).subscribe(
            (res) => {
              if (res.success){
                this.userData.firstName = res.data.firstName;
                this.userData.lastName = res.data.lastName;
                this.userData.photoURL = res.data.photoURL;
                this.blockUI.stop();
              }
              else {
                this.blockUI.stop();
              }
            }
          )
          this.blockUI.stop();
        } else{
          this.blockUI.stop();
        }
      },
      (error) => {
        this.blockUI.stop();
      }
    );
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  openAccountModification(): void{
    const modalRef = this.headerModalService.open(AccountModificationComponent);
    modalRef.componentInstance.id = this.userData.id;
    modalRef.result.then((result) => {
      this.getCoreUserById(localStorage.getItem('token'));
      // console.log(`Closed with: ${result}`);
    }, (reason) => {
      this.getCoreUserById(localStorage.getItem('token'));
      // console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }

  openAccountTab(): void{
    this.router.navigate(['/bo/mon-compte']);
  }

  signOut() {
    this.authService.signOut();
    this.userData = {
      id: "",
      firstName: "",
      lastName: "",
      idRole: "",
      role: "",
      photoURL: "",
    };
    this.router.navigate(['/bo/connexion']);
  }

  redirectLogin(){
    this.router.navigate(['/bo/connexion']);
  }
}

export interface user{
  id: string,
  firstName: string,
  lastName: string,
  idRole: string,
  role: string,
  photoURL: string,
}
