export class ConfigDB {
  static data = {
  settings: {
  layout_type: 'ltr',
  sidebar: {
     type: 'compact-wrapper',
     body_type: 'sidebar-icon'
  },
  sidebar_setting: 'default-sidebar',
  sidebar_backround: 'dark-sidebar'
},
color: {
  layout_version: '',
  color: '',
  primary_color: '',
  secondary_color: '',
  mix_layout: 'default'
},
router_animation: 'fadeIn'
}
}
