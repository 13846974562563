import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false
	public fullScreen = false;

	constructor() {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

  MENUITEMS: Menu[] = [
    // { path: '', icon: 'user', title: 'Mon compte', type: 'sub', active: false,
	// 	children: [
	// 		{ path: '/bo/compte', icon: 'users', title: 'Mon compte', type: 'link'}
	// 	]
	// },
	{ path: '/bo/mon-compte', icon: 'users', title: 'Mon compte', type: 'link'},
    { path: '/bo/commandes', icon: 'shopping-cart', title: 'Commandes', type: 'link' },
    // TODO: group clients and utilisateurs into utilisateurs with 2 children
    // { path: '/bo/clients', icon: 'users', title: 'Clients', type: 'link' },
    // after authen -> push to navbar list
    // { path: '/bo/utilisateurs', icon: 'users', title: 'Utilisateurs', type: 'link' }
	// { path: '/bo/fournisseurs', icon: 'users', title: 'Fournisseurs', type: 'link' },
	// { path: '/bo/boutiques', icon: 'users', title: 'Adhérents', type: 'link' },
	{ path: '', icon: 'users', title: 'Utilisateurs', type: 'sub', active: false,
		children:[
			{ path: '/bo/clients', icon: 'users', title: 'Clients', type: 'link' },
		]
	}
  ]

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
