<!-- Page Header Start-->
<div class="page-main-header" [ngClass]="{'open' : navServices.collapseSidebar}" id="page-main-header">
  <div class="main-header-right row">
    <div class="main-header-left d-lg-none">
      <div class="logo-wrapper">
      </div>
    </div>
    <div class="mobile-sidebar">
      <div class="media-body text-right switch-sm">
        <label class="switch">
          <a (click)="collapseSidebar()">
            <app-feather-icons class="btn_sidebar" id="sidebar-toggle" [icon]="'align-left'"></app-feather-icons>
          </a>
        </label>
      </div>
    </div>
    <div class="nav-right col">
      <ul class="nav-menus" [ngClass]="{'open' : openNav}">
        <li>
        </li>

        <!-- Buttons MDM /SM -->
        <!-- <li class="">
          <a href="">MDM</a>
        </li> -->
        <!-- dropdown login/logout/my account -->
        <li class="onhover-dropdown">
          <!-- if not logged in -->
          <div class="media align-items-center" *ngIf="this.userData.id === ''">
            <img class="align-self-center pull-right img-50 rounded-circle" src="assets/images/RMM_user_logo_2.svg"
              alt="header-user" (click)="redirectLogin()">
            <!-- <div class="dotted-animation"><span class="animate-circle"></span><span class="main-circle"></span></div> -->
          </div>
          <!-- if user logged in -->
          <div class="media align-items-center user-block" *ngIf="this.userData.id">
            <img class="align-self-center pull-right img-50 rounded-circle"
              [src]="this.userData.photoURL === '' || this.userData.photoURL === undefined ? 'assets/images/RMM_user_logo.svg' : this.userData.photoURL"
              alt="header-user">
            <!--            <div class="dotted-animation"><span class="animate-circle"></span><span class="main-circle"></span></div>-->
            <div class="user_name">{{this.userData.firstName}} {{this.userData.lastName}}</div>
          </div>
          <ul class="profile-dropdown onhover-show-div p-20" *ngIf="this.userData.id">
            <!-- <li><a href="javascript:void(0)" (click)="openAccountModification()">
                <app-feather-icons [icon]="'user'"></app-feather-icons>Edit Profile
              </a></li> -->
            <li><a href="javascript:void(0)" (click)="openAccountTab()">
                <app-feather-icons [icon]="'user'"></app-feather-icons>Mon compte
              </a></li>
            <li><a href="javascript:void(0)" (click)="this.signOut()">
                <app-feather-icons [icon]="'log-out'"></app-feather-icons>Déconnexion
              </a></li>
          </ul>
        </li>
      </ul>
      <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
        <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
      </div>
    </div>
  </div>
</div>
<!-- Page Header Ends -->